// _app.tsx
import { DataProvider } from "@/contexts/dataProvider";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Amplify } from "aws-amplify";
import type { AppProps } from "next/app";
import config from "../../aws-exports";
import MenuBar from "../components/MenuBar";
import theme from "../styles/theme";

Amplify.configure({
  ...config,
});

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <DataProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MenuBar />
          <Box mt={4}>
            <Component {...pageProps} />
          </Box>
        </ThemeProvider>
      </LocalizationProvider>
    </DataProvider>
  );
}

export default MyApp;
