import { useDataContext } from "@/contexts/dataProvider";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  AppBar,
  Badge,
  Box,
  Button,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from "@mui/material";
import { Auth } from "aws-amplify";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { JobData } from "./job/types";
import { ResumeData } from "./resumes/types";
import { TenantData } from "./tenant/types";
import UserData from "./users/types";

const MenuBar: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [notificationAnchorEl, setNotificationAnchorEl] =
    useState<null | HTMLElement>(null);
  const router = useRouter();
  const {
    tenantData,
    updateTenantData,
    tenantList,
    updateTenantList,
    jobData,
    updateJobData,
    jobList,
    updateJobList,
    userData,
    updateUserData,
    resumeData,
    updateResumeData,
    resumeList,
    updateResumeList,
  } = useDataContext();


  const [availableJobs, setAvailableJobs] = useState(0);
  const menuBarStyle = { color: "#9CD2D3", fontWeight: "bold" };

  // ページがロードされたときにダウンロード可能な案件の数を取得
  useEffect(() => {
    const jobCount = jobList.length;
    setAvailableJobs(jobCount);
  }, [jobList]);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    if (jobList.length !== 0) {
      setNotificationAnchorEl(event.currentTarget);
    }
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  const handleJobClick = (job: any) => {
    updateJobData(job);
    setNotificationAnchorEl(null);
    setAnchorEl(null);
    setMobileMenuAnchorEl(null);
    router.push("/download");
  };

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      updateTenantData({} as TenantData);
      updateTenantList([]);
      updateUserData({} as UserData);
      updateJobList([]);
      updateJobData({} as JobData);
      updateResumeData({} as ResumeData);
      updateResumeList([]);
      setAnchorEl(null);
      setNotificationAnchorEl(null);
      setMobileMenuAnchorEl(null);
      console.log("Logged out");
      router.push("/login");
    } catch (error) {
      console.log("Error logging out:", error);
    }
  };

  const handleNavigation = (path: string) => {
    router.push(path);
    handleMenuClose();
    handleMobileMenuClose();
  };

  if (!userData) {
    return (
      <AppBar position="static">
        <Toolbar>
          <Typography>JOBoost</Typography>
        </Toolbar>
      </AppBar>
    );
  }

  return (
    <AppBar position="static">
      <Toolbar
        sx={{
          justifyContent: {
            xs: "center", // スマホサイズの時に中央揃え
            md: "flex-start", // mdサイズ以上の時には左揃え
          },
        }}
      >
        {" "}
        {/* paddingを追加 */}
        <Hidden mdUp>
          <IconButton
            edge="start"
            style={menuBarStyle}
            aria-label="menu"
            onClick={handleMobileMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={mobileMenuAnchorEl}
            open={Boolean(mobileMenuAnchorEl)}
            onClose={handleMobileMenuClose}
          >
            <MenuItem onClick={() => handleNavigation("/")}>ホーム</MenuItem>
            <MenuItem onClick={() => handleNavigation("/profile")}>
              プロフィール
            </MenuItem>
            <MenuItem onClick={() => handleNavigation("/upload")}>
              アップロード
            </MenuItem>
            <MenuItem onClick={() => handleNavigation("/download")}>
              ダウンロード
            </MenuItem>
          </Menu>
        </Hidden>
        <Hidden smDown>
          <Button style={menuBarStyle} onClick={() => handleNavigation("/")}>
            ホーム
          </Button>
          <Button
            style={menuBarStyle}
            onClick={() => handleNavigation("/profile")}
          >
            プロフィール
          </Button>
          <Button
            style={menuBarStyle}
            onClick={() => handleNavigation("/upload")}
          >
            アップロード
          </Button>
          <Button
            style={menuBarStyle}
            onClick={() => handleNavigation("/download")}
          >
            ダウンロード
          </Button>
        </Hidden>
        <Box flexGrow={1} /> {/* 右側のアイコンボタンを右端に配置 */}
        <IconButton style={menuBarStyle} onClick={handleNotificationClick}>
          <Badge badgeContent={availableJobs} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Menu
          anchorEl={notificationAnchorEl}
          open={Boolean(notificationAnchorEl)}
          onClose={handleNotificationClose}
        >
          {jobList.map((job, index) => (
            <MenuItem key={index} onClick={() => handleJobClick(job)}>
              {job.resume_user_name}
            </MenuItem>
          ))}
        </Menu>
        <IconButton style={menuBarStyle} onClick={handleMenuClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleSignOut}>ログアウト</MenuItem>
          <MenuItem onClick={() => handleNavigation("/forgot-password")}>
            パスワード再設定
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default MenuBar;
