import { ResumeData } from "@/components/resumes/types";
import { TenantData } from "@/components/tenant/types";
import UserData from "@/components/users/types";
import { useContext, useState } from "react";
import DataContext from "./dataContext";
import { JobData } from "@/components/job/types";

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [tenantData, setTenantData] = useState<TenantData | null>(null);
  const [tenantList, setTenantList] = useState<TenantData[]>([]);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [userList, setUserList] = useState<UserData[]>([]);
  const [resumeData, setResumeData] = useState<ResumeData | null>(null);
  const [resumeList, setResumeList] = useState<ResumeData[]>([]);
  const [jobData, setJobData] = useState<JobData | null>(null);
  const [jobList, setJobList] = useState<JobData[]>([]);
  const [jobTableList, setJobTableList] = useState<JobData[]>([]);

  const updateTenantData = (newData: TenantData) => {
    setTenantData(newData);
  };

  const updateTenantList = (newDataList: TenantData[]) => {
    setTenantList(newDataList);
  };

  const updateUserData = (newData: UserData) => {
    setUserData(newData);
  };

  const updateUserList = (newDataList: UserData[]) => {
    setUserList(newDataList);
  };
  const updateResumeData = (newData: ResumeData) => {
    setResumeData(newData);
  };

  const updateResumeList = (newDataList: ResumeData[]) => {
    setResumeList(newDataList);
  };

  const updateJobData = (newData: JobData) => {
    setJobData(newData);
  };

  const updateJobList = (newDataList: JobData[]) => {
    setJobList(newDataList);
  };

  const updateJobTableList = (newDataList: JobData[]) => {
    setJobTableList(newDataList);
  };

  return (
    <DataContext.Provider
      value={{
        tenantData,
        updateTenantData,
        tenantList,
        updateTenantList,
        userData,
        updateUserData,
        userList,
        updateUserList,
        resumeData,
        updateResumeData,
        resumeList,
        updateResumeList,
        jobData,
        updateJobData,
        jobList,
        updateJobList,
        jobTableList,
        updateJobTableList,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);
