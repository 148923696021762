import { JobData } from "@/components/job/types";
import { ResumeData } from "@/components/resumes/types";
import { TenantData } from "@/components/tenant/types";
import UserData from "@/components/users/types";
import React from "react";

interface DataContextType {
  tenantData: TenantData | null;
  updateTenantData: (newData: TenantData) => void;
  tenantList: TenantData[] | [];
  updateTenantList: (newData: TenantData[]) => void;
  userData: UserData | null;
  updateUserData: (newData: UserData) => void;
  userList: UserData[] | [];
  updateUserList: (newData: UserData[]) => void;
  resumeData: ResumeData | null;
  updateResumeData: (newData: ResumeData) => void;
  resumeList: ResumeData[] | [];
  updateResumeList: (newData: ResumeData[]) => void;
  jobData: JobData | null;
  updateJobData: (newData: JobData) => void;
  jobList: JobData[] | [];
  updateJobList: (newData: JobData[]) => void;
  jobTableList: JobData[] | [];
  updateJobTableList: (newData: JobData[]) => void;
}

// 初期値としてnullを持つオブジェクトを渡す
const DataContext = React.createContext<DataContextType>({
  tenantData: null,
  updateTenantData: () => { },
  tenantList: [],
  updateTenantList: () => { },
  userData: null,
  updateUserData: () => { },
  userList: [],
  updateUserList: () => { },
  resumeData: null,
  updateResumeData: () => { },
  resumeList: [],
  updateResumeList: () => { },
  jobData: null,
  updateJobData: () => { },
  jobList: [],
  updateJobList: () => { },
  jobTableList: [],
  updateJobTableList: () => { },
});

export default DataContext;