import { TableCell, TableRow } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { styled } from "@mui/material/styles";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center', // テキストを中央揃えに
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0, // 最後の行の下ボーダーを削除
  },
  '& > .MuiTableCell-root': { // TableRowの子としてのTableCellにスタイルを適用
    fontWeight: 'normal', // 通常の文字の太さ
    fontSize: '1rem', // フォントサイズをデフォルトに
    textAlign: 'center', // テキストを中央揃えに
  }
}));

export const StyledHeaderRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#16A69D',  // 背景色を設定
  '& > .MuiTableCell-root': { // TableRowの子としてのTableCellにスタイルを適用
    color: '#FFFFFF', // 文字色を設定
    // fontWeight: 'bold', // 太字
    // fontSize: '1.1rem', // フォントサイズを少し大きく
    textAlign: 'center', // テキストを中央揃えに
  }
}));

export const theme = createTheme({
  palette: {
    primary: {
      main: '#16A69D',
    },
    secondary: {
      main: '#3f51b5',
    },
    error: {
      main: '#f44336',
    },
    warning: {
      main: '#ff9800',
    },
    info: {
      main: '#2196f3',
    },
    success: {
      main: '#4caf50',
    },
    background: {
      default: '#FFFFFF',
    },
  },
});

export const customPalette = {
  menubarFontColor: '#9CD2D3',
};

export default theme;
